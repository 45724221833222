import { useEffect, useState } from 'react';
import { fetchDocument } from '../fetchDocument';
import { SiteSlug, getMarketSite } from '@volvo-cars/market-sites';
import { type JSONArticleData } from '@volvo-cars/ced-os-react-components/lib/types/render/types';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';

interface DocumentRendererProps {
  documentId: string;
  siteSlug?: SiteSlug;
}

export const useDocumentRenderer = ({
  documentId,
  siteSlug: currentSiteSlug,
}: DocumentRendererProps) => {
  const { siteSlug } = useVolvoCarsUrl();
  const { locale } = getMarketSite(siteSlug);
  const [content, setContent] = useState<JSONArticleData>();
  const [currentDocumentId, setCurrentDocumentId] = useState('');
  const [anchor, setAnchor] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [trail, setTrail] = useState<string[]>([]);
  const [date, setDate] = useState<string>();

  useEffect(() => {
    setCurrentDocumentId(documentId);
    setTrail([]);
    setAnchor('');
  }, [documentId]);

  useEffect(() => {
    (async () => {
      if (!currentDocumentId) return;
      setIsLoading(true);
      try {
        const document = await fetchDocument({
          documentId: currentDocumentId,
          locale,
          siteSlug: currentSiteSlug || siteSlug || 'intl',
        });
        if (document?.jsonContent) {
          setContent(document?.jsonContent);
          setDate(new Date(document?.lastUpdate).toLocaleDateString());
        } else if (document?.content) {
          setContent(document?.content);
        }
      } catch (error) {
        console.error(error);
        setContent(undefined);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [currentDocumentId, currentSiteSlug, locale, siteSlug]);

  const loadPreviousDocument = () => {
    if (trail.length) {
      const newDocumentId = trail.at(-1);
      if (newDocumentId) {
        setIsLoading(true);
        setTrail((prev) => prev.slice(0, -1));
        setCurrentDocumentId(newDocumentId);
      }
    }
  };

  const onInternalClick = (id: string) => {
    let anchor = '';
    //Checking if the link is pointing to a specific part of the document with anchor tag
    if (id.includes('#')) {
      [id, anchor] = id.split('#');
      setAnchor(anchor);
    } else {
      setAnchor('');
    }
    //Adding the document to the trail if it the link is not poiting to the same document
    if (id !== currentDocumentId) {
      setTrail((current) => [...current, currentDocumentId]);
    }
    setCurrentDocumentId(id);
  };

  return {
    onInternalClick,
    loadPreviousDocument,
    anchor,
    content,
    isLoading,
    trail,
    date,
    setAnchor,
  };
};
