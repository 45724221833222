import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import React from 'react';

export interface ErrorMessageProps {
  href: string;
}

export const ErrorView = ({ href }: ErrorMessageProps) => {
  const translate = useSharedComponentsTranslate();

  return (
    <div className="h-full flex-col justify-center items-center">
      <p className="font-24 font-medium text-center mb-16">
        {translate('ContextualSupportContent.errorMessage.somethingWentWrong')}
      </p>
      <p className="text-secondary font-20">
        {translate('ContextualSupportContent.errorMessage.displayError')}{' '}
        <span>
          <a href={href} className="text-accent-blue link-underlined font-20">
            {' '}
            {translate('ContextualSupportContent.errorMessage.visitSite')}{' '}
          </a>
        </span>
        {translate('ContextualSupportContent.errorMessage.findAllArticles')}{' '}
      </p>
    </div>
  );
};
