'use client';
import type { PropsWithChildren, ReactNode } from 'react';
import React, { useEffect, useState, useCallback } from 'react';
import {
  ExitIntent,
  LeadsExternalContextProps,
} from '@vcc-package/leads-utils';
import { Dialog, DialogMain } from '@vcc-package/overlays';
// eslint-disable-next-line no-restricted-imports
import { isStorybook } from '@vcc-www/constants';
import { LeadsApp } from '../constants';
import { useEmbeddableFormsTracking } from '../hooks/useEmbeddableFormsTracking';
import KeepMeUpdatedIntroductionPage from './KeepMeUpdatedIntroductionPage';
import RequiresEvergageAndEnabledPageTriggersToLoad from './RequiresEvergageAndEnabledPageTriggersToLoad';
import { useRecaptchaAndDialogCompatibility } from '../hooks/useRecaptchaAndDialogCompatibility';
import { useHashTrigger } from '../hooks/useHashTrigger';

export const DialogTrigger = (
  props: PropsWithChildren<{
    title: string;
    dialogContent: ReactNode;
    appId: LeadsApp;
    onExitIntentIntroductionComponent?: LeadsExternalContextProps['onExitIntentIntroductionComponent'];
    hashTrigger?: string;
    consumerApp: string;
  }>,
) => {
  const [embeddedFormDialogOpen, setEmbeddedFormDialogOpen] = useState(false);
  useRecaptchaAndDialogCompatibility({
    dialogOpen: embeddedFormDialogOpen,
    setDialogOpen: setEmbeddedFormDialogOpen,
  });
  const { onExitIntentIntroductionComponent, appId, consumerApp } = props;
  const track = useEmbeddableFormsTracking({
    inDialog: true,
    appId,
    isEmbeddableForms: true,
    dialogOpen: embeddedFormDialogOpen,
    consumerApp,
  });

  const showMainDialogAndTrack = useCallback(() => {
    setEmbeddedFormDialogOpen(true);
    track.pageView();
    if (props.appId !== LeadsApp.QuoteRequest) {
      track.formLoad();
    }
  }, [track, props.appId]);

  const { hashTriggerCleanup } = useHashTrigger({
    showMainDialogAndTrack,
    hashTrigger: props?.hashTrigger,
  });

  const onCloseEmbeddedFormDialog = useCallback(() => {
    hashTriggerCleanup();
    setEmbeddedFormDialogOpen(false);
  }, [hashTriggerCleanup, setEmbeddedFormDialogOpen]);

  useEffect(() => {
    if (!window.location.href.includes('testing=true') && !isStorybook) return;

    window.Evergage = {};
    console.log('Evergage mock added');
    window.Evergage.getCurrentPage = function () {
      return { user: { enablePageTriggers: true } };
    };
  }, []);

  const DialogContent = (
    <>
      <button
        type="button"
        onClick={showMainDialogAndTrack}
        className="w-full"
        style={{ zIndex: 1 }}
      >
        {props.children}
      </button>

      <Dialog
        open={embeddedFormDialogOpen}
        onClose={onCloseEmbeddedFormDialog}
        title={props.title}
      >
        <DialogMain>{props.dialogContent}</DialogMain>
      </Dialog>
    </>
  );

  return (
    <>
      {onExitIntentIntroductionComponent === 'KeepMeUpdatedIntroductionPage' ? (
        <RequiresEvergageAndEnabledPageTriggersToLoad>
          <ExitIntent componentId={onExitIntentIntroductionComponent}>
            <KeepMeUpdatedIntroductionPage
              onClose={showMainDialogAndTrack}
              consumerApp={consumerApp}
            />
          </ExitIntent>
          {DialogContent}
        </RequiresEvergageAndEnabledPageTriggersToLoad>
      ) : (
        DialogContent
      )}
    </>
  );
};
