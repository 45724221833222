'use client';
import { DiscoveryCardHorizontal } from '@vcc-package/discovery-cards';
import { Markdown } from '@vcc-package/text';
import { usePartExchangeTool } from '@vcc-www/part-exchange-tool';
import React from 'react';
import { Spacing } from 'src/components/layout/Spacing';
import type cbvDiscoveryCardProps from 'src/content-management/editorial-components/DiscoveryCard/DiscoveryCard.props';

export const CustomTradeInDiscoveryCard = ({
  content,
}: cbvDiscoveryCardProps) => {
  const { showPartExchangeTool } = usePartExchangeTool();
  const { discoveryCard, disclaimer } = content;

  const { responsiveImages, layout, label, heading, bodyText, cta } =
    discoveryCard || {};

  if (!responsiveImages) return null;

  return (
    <Spacing>
      <div className="container">
        <DiscoveryCardHorizontal
          layout={layout}
          responsiveImages={responsiveImages}
        >
          {label && <p>{label}</p>}
          <h2 className="heading-2 mt-16">{heading}</h2>
          {bodyText && <p className="mt-16">{bodyText}</p>}
          {cta && (
            <button
              onClick={() => showPartExchangeTool({ title: 'Part Exchange' })}
              type="button"
              className="button-outlined mt-24"
            >
              {cta.text}
            </button>
          )}
        </DiscoveryCardHorizontal>

        {disclaimer && (
          <Markdown
            className="micro text-secondary mt-32 md:text-center"
            markdown={disclaimer}
          />
        )}
      </div>
    </Spacing>
  );
};
