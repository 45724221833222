import React, { ReactNode, createContext, useState } from 'react';

type DocumentViewerContextType = {
  showBackButton: boolean;
  setShowBackButton: (status: boolean) => void;
  setHandleBackClick: (status: boolean) => void;
  handleBackClick: boolean;
};

/**
 * The context for the document viewer
 */
export const DocumentViewerContext = createContext<DocumentViewerContextType>({
  showBackButton: false,
  setShowBackButton: () => {},
  setHandleBackClick: () => {},
  handleBackClick: false,
});

interface DocumentViewerProviderProps {
  children: ReactNode;
}

/**
 * @description The provider for the Document viewer component - fetches the the documents. monitors the trail and loading states
 * @param children - The children to render
 */
export const DocumentViewerProvider = ({
  children,
}: DocumentViewerProviderProps) => {
  const [handleBackClick, setHandleBackClick] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);

  return (
    <DocumentViewerContext.Provider
      value={{
        showBackButton,
        setShowBackButton,
        setHandleBackClick,
        handleBackClick,
      }}
    >
      {children}
    </DocumentViewerContext.Provider>
  );
};
