import React from 'react';
import styles from '../discovery-card.module.css';
import { LinkEntry } from '@volvo-cars/content-management-client';
import { cssMerge } from '@volvo-cars/css/utils';

type CardProps = React.PropsWithChildren<
  {
    cta: LinkEntry;
    layout?: 'default' | 'reversed';
  } & React.HTMLAttributes<HTMLAnchorElement>
>;

const ClickableDiscoveryCard = ({
  children,
  cta,
  className,
  layout = 'default',
  ...rest
}: CardProps) => {
  const { style, ...restProps } = rest;

  return (
    <a
      href={cta.href}
      target={cta.target}
      aria-label={cta.accessibilityLabel}
      style={
        {
          textDecoration: 'none',
          '--background-color': 'var(--v-color-background-secondary)',
          ...style,
        } as React.CSSProperties
      }
      className={cssMerge(
        'link-plain w-full tap-area',
        styles.discovery_card,
        styles.clickable,
        className,
        layout === 'reversed' && styles.reversed,
      )}
      {...restProps}
    >
      {children}
    </a>
  );
};

export default ClickableDiscoveryCard;
