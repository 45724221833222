import React, { useContext, useEffect } from 'react';
import { DocumentContent } from './DocumentContent';
import { getMarketSite, SiteSlug } from '@volvo-cars/market-sites';
import { urlFromString, useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { DocumentContentSkeleton } from './DocumentContentSkeleton';
import { ErrorView } from './ErrorView';
import { DocumentViewerContext } from './DocumentViewerProvider';
import { useDocumentRenderer } from './hooks/useDocumentRenderer';

export interface DocumentRendererProps {
  documentId: string;
  siteSlug?: SiteSlug;
}

/**
 * This component is used fetch data and pass it to document showing component
 * @param siteSlug - The market siteSlug if it is passed from parent component
 * @param documentId - The document id which is being shown

 */
export const DocumentRenderer = ({
  siteSlug,
  documentId,
}: DocumentRendererProps) => {
  const siteSlugFromUrl = useVolvoCarsUrl().siteSlug;
  const currentSiteSlug = siteSlug || siteSlugFromUrl || 'intl';
  const { languageDirection, locale } = getMarketSite(currentSiteSlug);
  const {
    content,
    isLoading,
    loadPreviousDocument,
    onInternalClick,
    anchor,
    trail,
    date,
    setAnchor,
  } = useDocumentRenderer({
    documentId,
    siteSlug,
  });
  const { handleBackClick, setHandleBackClick, setShowBackButton } = useContext(
    DocumentViewerContext,
  );

  useEffect(() => {
    if (trail.length !== 0) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  }, [setShowBackButton, trail.length]);

  useEffect(() => {
    if (handleBackClick) {
      loadPreviousDocument();
      setAnchor('');
      setHandleBackClick(false);
    }
  }, [handleBackClick, loadPreviousDocument, setAnchor, setHandleBackClick]);

  const { href } = urlFromString(`${currentSiteSlug}/support`);

  return (
    <>
      {isLoading ? (
        <DocumentContentSkeleton />
      ) : content ? (
        <DocumentContent
          locale={locale}
          direction={languageDirection ?? 'ltr'}
          onInternalClick={onInternalClick}
          anchor={anchor}
          content={content}
          documentId={documentId}
          date={date}
        />
      ) : (
        <ErrorView href={href} />
      )}
    </>
  );
};
