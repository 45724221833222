import { supportContentRequest } from '../api/supportContentRequest';
import { QUERY_DOCUMENT } from '../graphql/DocumentQuery';

export const fetchDocument = async ({
  siteSlug,
  documentId,
  locale,
}: {
  siteSlug: string;
  documentId: string;
  locale: string;
}) => {
  const data = await supportContentRequest(QUERY_DOCUMENT, {
    siteSlug: siteSlug,
    language: locale,
    documentId,
  });

  if (data) {
    const document = data?.market?.knowledge?.document;
    return document;
  }

  return null;
};
