import React from 'react';
import styles from './documentViewer.module.css';

export const DocumentContentSkeleton = () => (
  <div className="container">
    <div className="h-32 mb-16" />
    <div className={`px-16 py-24 bg-primary ${styles.documentsSkeleton}`}>
      <div className="h-24 mb-8 bg-secondary rounded" />
      <div className={`h-24 mb-24 bg-secondary rounded ${styles.width80}`} />
      <div className="h-24 mb-8 bg-secondary rounded" />
      <div className={`h-24 mb-8 bg-secondary rounded ${styles.width90}`} />
      <div className={`h-24 mb-24 bg-secondary rounded ${styles.width50}`} />
      <div className={`mb-24 bg-secondary rounded ${styles.height5rem}`} />

      <div className={`h-24 mb-24 bg-secondary rounded ${styles.width80}`} />
      <div className="h-24 mb-8 bg-secondary rounded" />
      <div className="h-24 mb-8 bg-secondary rounded" />
      <div className="h-24 mb-8 bg-secondary rounded" />
      <div className={`h-24 mb-16 bg-secondary rounded ${styles.width30}`} />
      <div className={`h-64 bg-secondary rounded mb-16 ${styles.height7rem}`} />
      <div className={`h-24 mb-16 bg-secondary rounded ${styles.width30}`} />
      <div className="h-24 mb-8 bg-secondary rounded" />
      <div className={`h-24 mb-8 bg-secondary rounded ${styles.width90}`} />
      <div className={`h-24 mb-24 bg-secondary rounded ${styles.width50}`} />
    </div>
  </div>
);
