import { gql } from 'graphql-request';

export const QUERY_DOCUMENT = gql`
  query Document($siteSlug: ID!, $language: [String!], $documentId: String!) {
    market(id: $siteSlug) {
      knowledge(language: $language) {
        document(documentId: $documentId) {
          documentId
          lastUpdate
          version
          attributes
          stringContent {
            title
            description
          }
          jsonContent {
            title
            description
            body
          }
          relatedDocuments {
            documentId
            stringContent {
              title
            }
          }
          applicableTo {
            title
            values
          }
        }
      }
    }
  }
`;
