'use client';
import React from 'react';
import styles from './PromotionalBanner.module.css';
import type Props from '../../content-management/content-types/promotional-banner/PromotionalBanner.props';
import { Icon } from '@volvo-cars/react-icons';
import { useDismiss } from '@volvo-cars/react-info-banner';
import { usePathname } from 'next/navigation';
import { hasConsentForFunctionalCookies } from '@vcc-package/storage/consent';

export const PromotionalBanner = (props: Props) => {
  const { bannerText, cta, hideCloseButton } = props;

  const pathname = usePathname();
  const { dismiss, visible } = useDismiss({
    key: pathname ?? '',
    persistToCookie: hasConsentForFunctionalCookies(),
  });

  return (
    visible && (
      <div
        data-autoid="promotionalBanner"
        className="bg-primary gap-16 py-16 px-24 flex-row md:items-center"
        data-color-mode="dark"
      >
        <div className="container-md gap-8 md:gap-16 flex-col md:flex-row justify-center md:pl-24">
          <p className={styles['truncate-2-lines']}>{bannerText}</p>
          <a
            className="link-underlined font-medium min-w-fit"
            href={cta.href}
            target={cta.target}
          >
            {cta.text}
          </a>
        </div>
        {!hideCloseButton && (
          <button type="button" className="flex pt-4 md:pt-0" onClick={dismiss}>
            <Icon alt="Exit" color="always-white" icon="x" size={16} />
          </button>
        )}
      </div>
    )
  );
};
