'use client';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentRenderer } from './DocumentRenderer';
import { SiteSlug } from '@volvo-cars/market-sites';
import { useTracker } from '@volvo-cars/tracking';
import {
  DocumentViewerContext,
  DocumentViewerProvider,
} from './DocumentViewerProvider';
import { DialogMain, Sheet } from '@vcc-package/overlays';
import styles from './documentViewer.module.css';

export interface DocumentViewerProps {
  siteSlug?: SiteSlug;
  documentId?: string;
  trackingData?: { eventCategory?: string; eventLabel?: string };
}

/**
 * This component is used to show overlay when the link with href to support/topic is being clicked
 * @param siteSlug - The site slug of market passed from parent component. Mainly used for storybook
 * @param documentId, - The document id sent from parent. This is optional. If not set, it will be set from the link
 * @param trackingData, - Overwritting the tracking eventlabel and eventCategory
 */
export const DocumentViewer = ({
  siteSlug,
  documentId,
  trackingData,
}: DocumentViewerProps) => {
  const hrefPattern = '/support/topic/';
  const [isOpen, setIsOpen] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState('');
  const tracker = useTracker();

  const setDocumentIdFromLinkElement = useCallback(
    (anchor: HTMLElement) => {
      const documentHref = anchor.getAttribute('href')?.split(hrefPattern);
      const documentId = documentHref?.pop()?.replace(/\/+$/, ''); // Remove any trailing slashes if they exist
      tracker.customEvent({
        eventAction: 'link|click',
        eventLabel: trackingData?.eventLabel ?? (documentId || ''),
        eventCategory:
          trackingData?.eventCategory || 'contextual article overlay',
      });
      setCurrentDocumentId(documentId || '');
    },
    [tracker, trackingData],
  );
  useEffect(() => {
    if (documentId) {
      setCurrentDocumentId(documentId);
      setIsOpen(true);
    }
  }, [documentId]);

  const handleAnchorClick = useCallback(
    (target: HTMLElement, event: Event) => {
      const href = target.getAttribute('href');
      if (
        href &&
        href.includes(hrefPattern) &&
        target.hasAttribute('data-contextual-link')
      ) {
        event?.preventDefault();
        event?.stopPropagation();
        event?.stopImmediatePropagation();
        if (!isOpen) {
          setIsOpen(true);
          setDocumentIdFromLinkElement(target);
        }
      }
    },
    [isOpen, setDocumentIdFromLinkElement],
  );

  const handleClick = useCallback(
    (event: Event) => {
      const target = event?.target as HTMLElement;
      if (target && target.hasAttribute('href')) {
        handleAnchorClick(target, event);
      } else {
        //If the clicked item is not anchor itself. We want to find a nearest anchor
        const anchor = target.closest('a');
        if (anchor && anchor.hasAttribute('href')) {
          handleAnchorClick(anchor, event);
        }
      }
    },
    [handleAnchorClick],
  );

  useEffect(() => {
    if (!isOpen) {
      document.addEventListener('click', handleClick, { capture: true });
      return () => {
        document.removeEventListener('click', handleClick, { capture: true });
      };
    }
  }, [handleClick, isOpen]);

  return (
    <DocumentViewerProvider>
      <DocumentViewerContext.Consumer>
        {({ showBackButton, setHandleBackClick }) => {
          return (
            <Sheet
              open={isOpen}
              onClose={() => {
                setIsOpen(false);
              }}
              onBackNav={() => setHandleBackClick(true)}
              showBackButton={showBackButton}
              className={`pb-24 pt-0 ${styles.documentViewer}`}
            >
              <DialogMain>
                {isOpen && (
                  <DocumentRenderer
                    documentId={currentDocumentId}
                    siteSlug={siteSlug}
                  />
                )}
              </DialogMain>
            </Sheet>
          );
        }}
      </DocumentViewerContext.Consumer>
    </DocumentViewerProvider>
  );
};
