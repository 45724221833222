import React, { useEffect, useRef, useState } from 'react';
import '@volvo-cars/ced-os-react-components/lib/index.css';
import '@volvo-cars/ced-os-react-components/lib/theme-volvo.css';
import { type JSONArticleData } from '@volvo-cars/ced-os-react-components/lib/types/render/types';
import { JSONArticle, Theme } from '@volvo-cars/ced-os-react-components';

export interface DocumentContentProps {
  locale: string;
  direction?: 'ltr' | 'rtl';
  onInternalClick: (href: string) => void;
  anchor: string;
  content: JSONArticleData;
  documentId: string;
  date?: string;
}

/**
 * This component is used to show document content. It uses JSONArticle component from component rendering package to show the content
 * @param content - Document content
 * @param locale - The market
 * @param date - The last udpate of the document
 * @param documentId - The document id which is being shown
 * @param direction - Language direction
 * @param onInternalClick - Will be called when internal link is clicked
 * @param anchor - If the clicked internal link includes an anchor to inside of the document
 */
export const DocumentContent = ({
  locale,
  direction,
  onInternalClick,
  anchor,
  content,
  documentId,
  date,
}: DocumentContentProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [currentContent, setCurrentContent] = useState<
    JSONArticleData | undefined
  >(content);

  useEffect(() => {
    // Anchor exists in case of the internal link is linking to part of the same document
    setCurrentContent(content);
    if (anchor) {
      document.getElementById(anchor)?.scrollIntoView({ behavior: 'smooth' });
    } else {
      //DOM might not have fully rendered yet, leading to scrollIntoView targeting an element that's not yet accessible.
      //This is preventing that
      setTimeout(() => {
        divRef.current?.scrollIntoView();
      }, 0);
    }
  }, [anchor, content, currentContent]);

  return (
    <div ref={divRef}>
      <div className="pt-24">
        <JSONArticle
          data-article-id={documentId}
          data={content}
          metaSlot={
            date ? (
              <time dateTime={date} className="font-medium text-secondary">
                {date}
              </time>
            ) : undefined
          }
          theme={Theme.VOLVO}
          config={{
            locale: locale,
            direction,
            assets: {
              resizeParam: 'imwidth',
            },
            linkComponent: ({ children, href, ...linkProps }) => (
              <button
                type="button"
                //the regular styling classes are not working here
                style={{ textAlign: 'left' }}
                {...linkProps}
                onClick={() => {
                  //in case of external links
                  if (href.includes('http')) {
                    window.location.href = href;
                  } else {
                    onInternalClick(href);
                  }
                }}
              >
                {children}
              </button>
            ),
          }}
        />
      </div>
    </div>
  );
};
