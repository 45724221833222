'use client';

import React, { PropsWithChildren, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useInView } from '@vcc-www/hooks';
import { DialogTrigger } from './DialogTrigger';
import { EmbeddableForms } from './EmbeddableForms';
import { LeadsApp } from '@vcc-package/leads-utils/constants';
import { EmbeddableFormsWrapperProps } from './EmbeddableFormsWrapper';

export const EmbeddableFormsOverlay = (
  props: PropsWithChildren<
    EmbeddableFormsWrapperProps & { hashTrigger?: string }
  >,
) => {
  const [contentInViewRef, contentInView] = useInView<HTMLDivElement>({
    triggerOnce: true,
  });
  const [overlayTitle, setOverlayTitle] = useState('');
  const expandedProps = { ...props, setOverlayTitle };
  return !contentInView ? (
    <div ref={contentInViewRef} />
  ) : (
    <DialogTrigger
      title={overlayTitle}
      dialogContent={<EmbeddableForms {...expandedProps} />}
      onExitIntentIntroductionComponent={
        props.onExitIntentIntroductionComponent
      }
      appId={LeadsApp[props.formType]}
      hashTrigger={props.hashTrigger}
      consumerApp={props.consumerApp}
    >
      {props.children}
    </DialogTrigger>
  );
};
