import { APIResponse } from './types';
import {
  RecommendedCarsEngineOptions,
  RecommendedCarsEngineResponseType,
} from './types/CarData';
import { appendParamsToUrl } from './utils';

type GetRecommendedCarsReturnType<T extends RecommendedCarsOptions> =
  T['lean'] extends true
    ? APIResponse<string[]>
    : APIResponse<RecommendedCarsEngineResponseType[]>;

export type RecommendedCarsOptions = RecommendedCarsEngineOptions & {
  url?: string;
};

const getBaseUrl = () => {
  // Serverside
  if (typeof window === 'undefined') {
    switch (process.env.DEPLOY_ENV) {
      case 'test':
        return 'https://testwww.volvocars.com';
      case 'qa':
        return 'https://qawww.volvocars.com';
      default:
        return 'https://www.volvocars.com';
    }
  }

  // Else we are on clientside, use prod url if not on volvocars.com aka on dev
  if (!window.location.origin.includes('volvocars.com')) {
    return 'https://www.volvocars.com';
  }
  return window.location.origin;
};

export const RECOMMENDED_CARS_ENDPOINT = '/api/car-recommendation-engine';
const DEFAULT_URL = getBaseUrl() + RECOMMENDED_CARS_ENDPOINT;

const getRecommendedCars = async <T extends RecommendedCarsOptions>(
  opts: T,
): Promise<GetRecommendedCarsReturnType<T>> => {
  try {
    const {
      populates,
      images,
      excludeDataPoints,
      url: customEndpoint,
      powertrainTypes,
      bodyTypes,
      ...options
    } = opts;
    const urlWithParams = appendParamsToUrl(
      customEndpoint || DEFAULT_URL,
      options,
    );

    const res = await fetch(urlWithParams, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'force-cache',
      next: {
        revalidate: 60 * 5,
      },
      body: JSON.stringify({
        populates,
        images,
        excludeDataPoints,
        powertrainTypes,
        bodyTypes,
      }),
    });

    const json = await res.json();
    if (!res.ok) {
      if ('errors' in json && json.errors.length > 0) {
        const error = json.errors.shift();
        throw new Error(
          `[${res.status}][${res.statusText}]: ${
            error.message || 'Could not fetch recommended cars. '
          }`,
        );
      }
    }

    return json;
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(`Could not fetch recommended cars: ${e.message}`);
    }
    throw new Error('Could not fetch recommended cars');
  }
};

export default getRecommendedCars;
